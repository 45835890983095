import React, {useContext, useEffect, useState} from 'react'
import {ModalContext} from "../../../../contexts"
import {Button, Table} from "reactstrap"
import {capitalize} from "lodash"
import {prettyDate} from "../../../../helpers/utils"
import Select from "react-select"

const SecurityDetails = ({item}) => {
  const {closeModal} = useContext(ModalContext)
  const [itemData, setItemData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState({})
  const [statusOptions, setStatusOptions] = useState([])

  const getStatusOptions = () => {
    // setStatusOptions
  }

  useEffect(() => {
    if (!item) return

    const meta = item?.meta

    const metaData = Object.entries(meta)
      .map(([key, value]) => ({ title: key.split('_'), value }))

    setItemData([
      {title: ["Name"], value: item.name},
      {title: ["Created at"], value: prettyDate(item.created_at)},
      {title: ["Type"], value: capitalize(item.type)},
      ...metaData.filter(i => i.title[0] !== 'logo')
    ])
  }, [item])

  useEffect(() => {
    if (item) {
      getStatusOptions()
    }
  }, [item])

  return (
    <div className="d-flex flex-column">
      <Table responsive className="table table-striped">
        <tbody>
        {(itemData && itemData.length) ? itemData.map((item, idx) => {

          return (
            <React.Fragment key={idx}>
              <tr>
                <td className="text-nowrap fw-bold">
                  {item.title?.length ? item.title.map((i, idx) => {
                    return (
                      <span key={idx} className={`${idx === item.title.length - 1 ? '' : 'pe-1'}`}>
                        {idx === 0 ? capitalize(i) : i}
                      </span>
                    )
                  }) : null}
                </td>
                <td className="text-nowrap text-truncate fw-semibold">
                  {(item.title[0] === 'popular') ? (item.value ? 'On' : 'Off') : (item.value ? item.value : '-')}
                </td>
              </tr>
            </React.Fragment>
          )
        }) : null}
        <tr className="text-nowrap">
        </tr>

        </tbody>
      </Table>

      <div className="form-group mb-4 d-flex align-items-center">
        <span className="mx-3 fw-bold">Status</span>
        <Select
          className="w-100"
          placeholder="Choose..."
          name="status"
          value={selectedStatus}
          onChange={selected => setSelectedStatus(selected)}
          options={statusOptions}
        />
      </div>

      <div className="d-flex w-100 justify-content-between">
        <Button type="button" className="btn btn-warning" onClick={closeModal}>Close</Button>
        <Button type="button" className="btn btn-success" onClick={() => {
        }}>Save</Button>
      </div>
    </div>
  )
}

export default SecurityDetails
