import axios from "axios"
import {prepareQueryString} from "./utils";

const getLoggedinUser = () => {
  const user = localStorage.getItem('authUser')
  if (!user) {
    return null
  } else {
    return JSON.parse(user)
  }
}

const loggingOutUser = (uri) => {
  if (!uri) uri = '/login'

  localStorage.setItem('authUser', null)

  if (window.location.pathname !== '/login') {
    window.location.href = uri
  }

  return true
}

const lockScreen = () => {
  let uri = '/pin-code'
  // localStorage.setItem('lockScreen', 'locked')

  if (window.location.href !== uri)
    window.location.href = uri

  return true
}

// Default
axios.defaults.baseURL = import.meta.env.VITE_APP_URL
// Accept
axios.defaults.headers.post["Accept"] = "application/json"
// Content type
axios.defaults.headers.post["Content-Type"] = "application/json"

// Authorization token
try {
  const authUser = JSON.parse(localStorage.getItem('authUser'))
  const token = authUser ? authUser.access_token : null
  if (token)
    axios.defaults.headers.common["Authorization"] = "Bearer " + token
} catch (e) {
  console.log("Can't get user token")
  console.log(e.message)
  loggingOutUser()
}

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message
    switch (error.response?.status || false) {
      case 500:
        message = "Internal Server Error"
        break
      case 422:
        message = error.response.data ? error.response.data : error.message
        break
      case 401:
        message = "Invalid credentials"
        loggingOutUser()
        break
      case 423:
        message = "Request locked!"
        lockScreen()
        break
      case 404:
        message = "Sorry! The data you are looking for could not be found."
        break
      case 413:
        message = "Request entity too large!"
        break
      default:
        message = error.message || error
    }
    return Promise.reject(message)
  }
)

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token
}

class APIClient {
  /**
   * Make get request
   *
   * @param url
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  get = (url, params) => {
    // console.log('axios', axios.defaults.headers.common)
    let response

    if (params) {
      // Object.keys(params).map(key => {
      //     if (Array.isArray(params[key])) {
      //         params[key].forEach((val) => {
      //             paramKeys.push(key + '[]=' + encodeURIComponent(val))
      //         })
      //     } else {
      //         paramKeys.push(key + '=' + encodeURIComponent(params[key]))
      //     }
      //
      //     return paramKeys
      // })
      //
      // const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : ""

      let queryString = prepareQueryString(params)

      response = axios.get(`${url}?${queryString}`, params)
    } else {
      response = axios.get(`${url}`, params)
    }

    return response
  }

  /**
   * Make post request
   *
   * @param url
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  post = (url, data) => {
    return axios.post(url, data)
  }

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data)
  }

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data)
  }

  put = (url, data) => {
    return axios.put(url, data)
  }

  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, {...config})
  }
}

export {APIClient, setAuthorization, getLoggedinUser}
