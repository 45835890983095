import React from "react"
import {Button, Table} from "reactstrap"

const ItemsEditRowsTable = (props) => {
  const {
    rows,
    handleProductDescriptionChange,
    handleQuantityChange,
    handleRateChange,
    currencyOptions,
    selectedCurrency,
    deleteRow,
    addRow,
    code
  } = props

  return (
    <>
      <Table id="invoice-table" className="table table-striped display-table">
        <thead>
        <tr>
          <th>Item</th>
          <th>Quantity</th>
          <th>Rate</th>
          <th>Amount</th>
        </tr>
        </thead>
        <tbody id="invoice-tbody">
        {rows.map((row) => (
          <tr key={row.id} className="template">
            <td className="col-md-5 col-lg-6 w-50">
              <div className="input-group flex-nowrap">
                <input
                  type="text"
                  className="form-control"
                  name={`item[${row.id}][product_description]`}
                  placeholder="Description of product"
                  defaultValue={row.product_description}
                  onChange={(e) => handleProductDescriptionChange(e, row.id)}
                  required
                />
              </div>
            </td>
            <td className="col">
              <div className="input-group flex-nowrap">
                <input
                  type="number"
                  className="calc-input item_quantity form-control"
                  name={`item[${row.id}][quantity]`}
                  placeholder="Quantity"
                  defaultValue={row.quantity}
                  onChange={(e) => handleQuantityChange(e, row.id)}
                  min={0}
                  required
                />
              </div>
            </td>
            <td className="col">
              <div className="input-group flex-nowrap">
                <input
                  type="number"
                  className="calc-input item_rate form-control"
                  name={`item[${row.id}][rate]`}
                  placeholder="Rate"
                  defaultValue={row.rate}
                  onChange={(e) => handleRateChange(e, row.id)}
                  min={0}
                  required
                />
              </div>
            </td>
            <td className="text-center align-middle">
              <span className="amount text-nowrap">
                {currencyOptions ?
                  <>
                    {Number(row.amount).toFixed(2)}{' '}{currencyOptions.find(option => option.value === selectedCurrency)?.label}
                  </> : code ?
                  <>
                    {Number(row.amount).toFixed(2)} {code}
                  </> : ''
                }
              </span>
            </td>
            <td className="text-end">
              <button
                type="button"
                onClick={() => deleteRow(row.id)}
                className="del_btn btn btn-danger">
                x
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      <Button type="button" className="btn btn-success" onClick={addRow}>New row</Button>
    </>
  )
}

export default ItemsEditRowsTable
