import React, {useContext, useEffect, useImperativeHandle, useReducer, useRef, useState} from 'react'
import {Button, Form} from "reactstrap"
import {ModalContext} from "../../contexts"
import FilterRow from "./Filters/FilterRow"
import {toast} from "react-toastify"
import {capitalize} from "lodash"
import {useSelector} from "react-redux";

function filtersDataReducer(fields, action) {
  switch (action.type) {
    case 'addRow': {
      return [...fields, {uuid: action.uuid}]
    }
    case 'insert': {
      // console.log(fields, action);
      // return [...fields]

      if (fields.find(i => i.name === action.data.name)) {
        return fields.map(obj => {
          if (obj.name === action.data.name) {
            return action.data
          }
          return obj
        })
      }

      return [...fields, {...action.data}]
    }
    case 'delete': {
      return [...fields.filter(field => field.uuid !== action.uuid)]
    }
    case 'clearAll': {
      return [{uuid: (Number(fields.pop()?.uuid) ?? 0) + 1}]
    }
  }
}

const initialFiltersData = []

const FiltersModal = ({filterFields, filtersHandler, activeFiltersList}) => {
  const {closeModal} = useContext(ModalContext)
  const [filters, setFilters] = useState([])
  const [filtersData, reduceFiltersData] = useReducer(filtersDataReducer, initialFiltersData)
  const methodsRef = useRef(null)
  // const activeFilters = Object.values(useSelector(s => s.TableFilters.filters?.customers ?? []))

  const submitHandler = (e) => {
    e.preventDefault()

    filtersData.pop()

    let validData = filtersData.filter(i => i.value.length && i.operator)
    let invalidData = filtersData.filter(i => !i.value.length || !i.operator)

    if (invalidData && invalidData.length) {
      invalidData.forEach(item => {
        toast.info(`Row "${item.title ? item.title : capitalize(item.name)}" is invalid and it will not be used in filters`)
      })
    }

    filtersHandler(validData)
    closeModal()
  }

  const insertFilterData = (filterData, uuid) => {
    reduceFiltersData({
      type: 'insert',
      data: filterData,
      uuid: uuid,
    })
  }

  const ifFilterExists = () => {
    if (activeFiltersList?.length) {
      let lastFilter = activeFiltersList[activeFiltersList.length - 1]

      activeFiltersList.forEach(item => (
        insertFilterData(
          {
            'uuid': item.uuid,
            'name': item.name,
            'value': item.value,
            'operator': item.operator,
            'title': item.title,
            'type': item.type
          }, item.uuid
        )
      ))

      addRow(lastFilter.uuid + 1)
    }
  }

  const removeFilterData = (uuid) => {
    reduceFiltersData({
      type: 'delete',
      uuid: uuid
    })
  }

  const clearAllFilterData = () => {
    reduceFiltersData({
      type: 'clearAll',
    })
  }

  const addRow = (newUuid) => {
    reduceFiltersData({
      type: 'addRow',
      uuid: newUuid
    })
  }

  useImperativeHandle(methodsRef, () => ({
    insertFilterData: (filterData, uuid) => insertFilterData(filterData, uuid),
    removeFilterData: (uuid) => removeFilterData(uuid),
    addRow: (uuid) => addRow(Number(uuid) + 1),
  }))

  useEffect(() => {
    if (filterFields) {
      setFilters(filterFields)
    }
  }, [filterFields])

  // useEffect(() => {
  //   ifFilterExists()
  // }, [activeFiltersList])

  useEffect(() => {
    // If we have some active filters in redux then retrieve it
    if (!activeFiltersList?.length) {
      addRow(1)
    }
  }, [])

  // useEffect(() => {
  //   console.log('filters', filters);
  // }, [filters]);

  useEffect(() => {
    if (activeFilters?.length) {
      activeFilters.forEach(i => insertFilterData(i, Date.now()))
    }
  }, [activeFilters])

  return (
    <React.Fragment>
      <Form onSubmit={submitHandler}>
        <div>
          {/*{filtersData.length && filtersData.map((row, idx) => {*/}
          {/*  return <FilterRow*/}
          {/*    // key={row.uuid}*/}
          {/*    key={idx}*/}
          {/*    filters={filters}*/}
          {/*    methodsRef={methodsRef}*/}
          {/*    uuid={row.uuid}*/}
          {/*    row={row}*/}
          {/*    // activeFilters={activeFiltersList}*/}
          {/*  />*/}
          {/*})}*/}
          {/*{activeFilters.length && activeFilters.map((row, idx) => {*/}
          {/*  return <FilterRow*/}
          {/*    // key={row.uuid}*/}
          {/*    key={'FilterRow_' + idx}*/}
          {/*    filters={filters}*/}
          {/*    methodsRef={methodsRef}*/}
          {/*    uuid={row.uuid}*/}
          {/*    row={row}*/}
          {/*    // activeFilters={activeFiltersList}*/}
          {/*  />*/}
          {/*})}*/}
        </div>
        <div className="d-flex justify-content-between pt-3">
          <div>
            <Button className="btn btn-sm btn-info me-2" type="button" color="danger" onClick={clearAllFilterData}>
              Clear all
            </Button>
          </div>
          <div>
            <Button className="btn btn-sm btn-info me-2" type="button" color="warning" onClick={closeModal}>
              Cancel
            </Button>
            <Button className="btn btn-sm" type="submit" color="primary">
              Apply
            </Button>
          </div>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default FiltersModal
