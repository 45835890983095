import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import Select from "react-select"
import {selectListData} from "../../../../api/general"
import {ModalContext} from "../../../../contexts"
import {toast} from "react-toastify"
import {createSecurity, getMeta} from "../../../../api/securities"

const addSecurity = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [securityOptions, setSecurityOptions] = useState([])
  const [pagesOption, setPagesOption] = useState([])
  const [selectedSecurity, setSelectedSecurity] = useState([])
  const [selectedSecuritiesFields, setSelectedSecuritiesFields] = useState({})

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    let meta = {}

    for (const key in data) {
      if (data.hasOwnProperty(key) && key.startsWith('meta_')) {
        const newKey = key.substring(5)
        meta[newKey] = data[key]
      }
    }

    const newData = {
      name: data.name,
      type: data.type,
      page_id: data.page_id,
      meta: meta
    }

    createSecurity(newData).then(r => {
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        closeModal()
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  const getSecuritiesMeta = (type) => {
    getMeta(type).then(r => {
      if (r.success) {
        setSelectedSecuritiesFields(r.fields)
      } else {
        toast.error(r.message)
      }
    })
  }

  useEffect(() => {
    selectListData('securities_types_list').then(r => {
      setSecurityOptions(r)
    })

    selectListData('pages_list').then(r => {
      setPagesOption(r)
    })
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Name</Label>
            <Input
              name="name"
              required
              placeholder="Name"
              autoComplete="off"
            />
          </div>
          <div className="form-group mb-3">
            <Label htmlFor="choices-multiple-default" className="form-label mb-0">Type</Label>
            <Select
              placeholder="Choose..."
              isClearable={true}
              name="type"
              onChange={(selected) => {
                setSelectedSecurity(selected)
                if (selected) {
                  getSecuritiesMeta(selected.value)
                }
              }}
              options={securityOptions}
              required
            />
          </div>

          <div className="form-group mb-3">
            <Label htmlFor="choices-multiple-default" className="form-label mb-0">Page</Label>
            <Select
              placeholder="Choose..."
              isClearable={true}
              name="page_id"
              options={pagesOption}
            />
          </div>

          {(selectedSecuritiesFields && Object.values(selectedSecuritiesFields)?.length) ?
            Object.values(selectedSecuritiesFields).map((field, idx) => {
              const isCheckBox = field.type === 'checkbox'
              const isPopular = field.label === 'Popular'
              const popularMessage = 'Check the box to display it in the popular list'

              return (
                <Col
                  className={`form-group mb-2 col-12 col-lg-6
                    ${isCheckBox ? 'd-flex align-items-center flex-row-reverse justify-content-end' : ''}`
                  }
                  key={idx}
                >
                  <Label className={`custom-label ${isCheckBox ? 'pb-1' : ''}`}>
                    {field.label}
                    {isPopular && <span className="ms-1 fs-12 text-muted">({popularMessage})</span>}
                  </Label>
                  <Input
                    placeholder={field.placeholder ?? 'Enter the value'}
                    name={`meta_${Object.keys(selectedSecuritiesFields)[idx]}`}
                    type={field.type}
                    autoComplete='off'
                  />
                </Col>
              )
            }) : ''
          }

          {/* Uncomment this block if need page id*/}
          {/*<div className="form-group mb-3">*/}
          {/*  <Label htmlFor="choices-multiple-default" className="form-label mb-0">Page</Label>*/}
          {/*  <Select*/}
          {/*    placeholder="Choose..."*/}
          {/*    isClearable={true}*/}
          {/*    name="aspect_ratio"*/}
          {/*    required*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="hstack justify-content-end mt-3">
            <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
            <Button type="submit" color="primary" className="btn">Save</Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export default addSecurity
