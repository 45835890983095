import React from 'react'
import {Card, CardBody} from "reactstrap"
import SecuritiesTable from "../../Secutities/Securities/SecuritiesTable"

const Securities = ({activeTab, customerId, methodsRef}) => {

  return (
    <Card>
      <CardBody>
        {activeTab === 'securities'
        ? <SecuritiesTable customerId={customerId} methodsRef={methodsRef} /> :
        ''
        }
      </CardBody>
    </Card>
  )
}

export default Securities
