import React, {useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react"
import {Link} from "react-router-dom"
import {deleteTransactions} from "../../api/transactions"
import {toast} from "react-toastify"
import BaseTable from "../../Components/Tables/BaseTable"
import BaseActionDropdown from "../../Components/Tables/Actions/BaseActionDropdown"
import {DropdownItem} from "reactstrap"
import {ModalContext} from "../../contexts"
import ChangeStatus from "./Modals/ChangeStatus"
import {useUserCan} from "../../Components/Hooks/UserHooks"
import TransactionBadgeIcon from "../../Components/Common/TransactionBadgeIcon"
import {isTwoPrecision, prettyDate, toTwoPrecision} from "../../helpers/utils"
import {setColumnsSetting} from "../../slices/transactions/reducer"
import {useSelector} from "react-redux"
import EditTransfer from "./Modals/EditTransfer"

//Import Crypto-SVG
import btc from "../../assets/images/svg/crypto-icons/btc.svg"
import eth from "../../assets/images/svg/crypto-icons/eth.svg"
import ltc from "../../assets/images/svg/crypto-icons/ltc.svg"
import dash from "../../assets/images/svg/crypto-icons/dash.svg"
import usdt from "../../assets/images/svg/crypto-icons/usdt.svg"
import cdn from "../../assets/images/svg/crypto-icons/cdn.svg"
import usd from "../../assets/images/svg/crypto-icons/usd.svg"
import eur from "../../assets/images/svg/crypto-icons/eur.svg"
import gbp from "../../assets/images/svg/crypto-icons/gbp.svg"
import xrp from "../../assets/images/svg/crypto-icons/xrp.svg"
import ada from "../../assets/images/svg/crypto-icons/ada.svg"
import doge from "../../assets/images/svg/crypto-icons/doge.svg"
import tusd from "../../assets/images/svg/crypto-icons/tusd.svg"
import {filtersList} from "../../api/general";

const TransactionsTable = ({walletId, withFilters, methodsRef, updateProfile}) => {
  const {openModal} = useContext(ModalContext)
  const [selectedRows, setSelectedRows] = useState([])
  const baseTableMethodsRef = useRef(null)
  const [dataUrl, setDataUrl] = useState('/spa/transactions/data')
  const columnsSetting = useSelector(s => s.Transactions.columnsSetting)
	const [selectedRowIds, setSelectedRowIds] = useState([])
  const [filters, setFilters] = useState()
  const storageName = 'transactions_columns_order'
  const tableRowsPerPage = 'transactions_rows_per_page'
  const filterStorageKey = 'transactions_filter'

	// Permissions
	const canViewCustomer = useUserCan('view_customer')
	const canFilterByDepartment = useUserCan('transaction_departments_filter')
	const canEditTransaction = useUserCan('edit_transaction')
	const canDeleteTransaction = useUserCan('delete_transaction')

	const cryptoIconsMapper = {
		BTC: btc,
		ETH: eth,
		LTC: ltc,
		DASH: dash,
		USDT: usdt,
		"USDT (ERC20)": usdt,
    CAD: cdn,
		USD: usd,
		EUR: eur,
		GBP: gbp,
		XRP: xrp,
		ADA: ada,
		DOGE: doge,
    TUSD: tusd
	}

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className="font-weight-bold fs-14">ID</span>,
        selector:
          canEditTransaction ?
            row => (
              <a href='#' onClick={() => editTransferHandler(row.id)}>
                #{row.id}{row.is_real === 'Yes' ? '*' : ''}{!row.is_visible ?
                <i className="ri-eye-off-line fs-10 ms-1"></i> : ''}
              </a>
            ) :
            row => <>#{row.id}{row.is_real === 'Yes' ? '*' : ''}{!row.is_visible ?
              <i className="ri-eye-off-line fs-10 ms-1"></i> : ''}</>,
        minWidth: '90px',
        omit: false,
        reorder: true,
      },
      {
        id: "type",
        name: <span className="font-weight-bold fs-14">Type</span>,
        width: "67px",
        selector: row =>
          <TransactionBadgeIcon
            transactionId={row.id}
            transactionType={row.type}
						invoiceId={row.invoice_id}
						invoiceAmount={row.usd_amount}
					/>,
        reorder: true,
        omit: false
      },
      {
        id: "created_date",
        name: <span className="font-weight-bold fs-14">Created date</span>,
        minWidth: "175px",
        selector: row => (prettyDate(row.created_at)),
        reorder: true,
        omit: false
      },
      {
        id: "base_asset",
        name: <span className="font-weight-bold fs-14">Base Asset</span>,
        selector: row => (
          <div className="d-flex align-items-center">
            <div className="avatar-xxs me-2">
              {/*{row.base_asset.currency.code}*/}
              <img
                src={cryptoIconsMapper[row.base_asset.currency.code]}
                className="img-fluid"
                alt={row.base_asset.currency.name}
              />
            </div>
            <span>{row.base_asset.currency.code}</span>
          </div>
        ),
        minWidth: "140px",
        reorder: true,
        omit: false
      },
      {
        id: "quote_asset",
        name: <span className="font-weight-bold fs-14">Quote Asset</span>,
        selector: row => (row.quote_asset ?
            <div className="d-flex align-items-center">
              <div className="avatar-xxs me-2">
                <img
                  src={cryptoIconsMapper[row.quote_asset.currency.code]}
                  className="img-fluid"
                  alt={row.base_asset.currency.name}
                />
              </div>
              <span>{row.quote_asset.currency.code}</span>
            </div> :
          '-'
        ),
        minWidth: "100px",
        reorder: true,
        omit: false
      },
      {
        id: "customer",
        name: <span className="font-weight-bold fs-14">Customer</span>,
	      selector: row => (canViewCustomer ?
			      <Link to={`/customers/show/${row.wallet.user?.id}`}>#{row.wallet.user?.id} {row.wallet.user?.name}</Link> :
			      <span>#{row.wallet.user?.id} {row.wallet.user?.name}</span>
	      ),
        minWidth: '180px',
        reorder: true,
        omit: false
      },
      {
        id: "amount",
        name: <span className="font-weight-bold fs-14">Amount</span>,
        selector: row => isTwoPrecision(row.base_asset.currency.code, row.base_amount),
        minWidth: '115px',
        reorder: true,
        omit: false
      },
      {
        id: "usd_amount",
        name: <span className="font-weight-bold fs-14">USD Amount</span>,
        selector: row => '$' + toTwoPrecision(row.usd_amount || 0),
        minWidth: '100px',
        reorder: true,
        omit: false
      },
			{
				id: "fee",
				name: <span className="font-weight-bold fs-14">Fee</span>,
				selector: (row) => {
					if (row.type === 'exchange') {
						return isTwoPrecision(row.quote_asset.currency.code, row.total_fee);
					} else if (row.type === 'deduction') {
						return isTwoPrecision(row.base_asset.currency.code, row.total_fee);
					} else {
						return '-';
					}
				},
        reorder: true,
        omit: false
			},
      {
        id: "fee_pct",
        name: <span className="font-weight-bold fs-14">Fee Pct</span>,
        selector: row => <span>{(row.fee_pct * 100).toFixed(1) * 1}%</span>,
        omit: false,
        reorder: true,
      },
      {
        id: "status",
        name: <span className="font-weight-bold fs-14">Status</span>,
        selector: row => (
          <span className={'badge badge-soft-' + row.status_color}>
                          {row.status === 'pending' ? (
	                          <i className="ri-time-line align-bottom me-1"></i>
                          ) : (row.status === 'executed' ? (
	                          <i className="ri-checkbox-circle-line align-bottom me-1"></i>
                          ) : (row.status === 'declined' ?
		                          <i className="ri-close-circle-line align-bottom me-1"></i> : ''
                          ))}
						{row.status_upper}
                        </span>
				),
				minWidth: '105px',
        reorder: true,
        omit: false
			},
			{
				id: "is_real",
				name: <span className="font-weight-bold fs-14">Is Real</span>,
				selector: row => row.is_real,
        omit: false,
        reorder: true,
			},
			{
				id: "is_sync",
				name: <span className="font-weight-bold fs-14">Is Sync</span>,
				selector: row => row.is_sync,
        omit: false,
        reorder: true,
			},
			{
				id: "actions",
				name: <span className="font-weight-bold fs-14">Actions</span>,
				selector: row => (
					<>
						{(canDeleteTransaction || canEditTransaction) &&
							<div className="hstack gap-3 flex-wrap">
								{canDeleteTransaction &&
									<Link
										to="#"
										onClick={() => {
											deleteTransactionsHandler([row.id])
										}}
										className="link-danger fs-15">
										<i className="ri-delete-bin-line"></i>
									</Link>}
							</div>}
					</>
				),
        omit: false,
        reorder: true,
			}
		], [canEditTransaction, canDeleteTransaction, canViewCustomer])

  const getFilterData = () => {
    filtersList( filterStorageKey).then(r => {
      setFilters(r)
    })
  }

  useEffect(() => {
    getFilterData()
  }, [canFilterByDepartment]);

	// const filters = useMemo(
	// 	() => [
	// 		{
	// 			title: 'ID',
	// 			name: 'transaction_id',
	// 			type: 'text',
	// 		},
	// 		{
	// 			title: 'Base Asset',
	// 			name: 'base_asset',
	// 			type: 'select',
	// 		},
	// 		{
	// 			title: 'Quote Asset',
	// 			name: 'quote_asset',
	// 			type: 'select',
	// 		},
	// 		{
	// 			title: 'Type',
	// 			name: 'type',
	// 			type: 'select',
	// 			select_data_name: 'transfer_types',
	// 		},
	// 		{
	// 			title: 'Status',
	// 			name: 'status',
	// 			type: 'select',
	// 			select_data_name: 'transaction_statuses'
	// 		},
	// 		(canFilterByDepartment &&
	// 			{
	// 				title: 'Department',
	// 				name: 'departments',
	// 				type: 'select',
	// 				is_multiple: true,
	// 			}
	// 		),
	// 		{
	// 			title: 'Is Sync',
	// 			name: 'is_sync_input',
	// 			type: 'select',
	// 			yes_or_no: true,
	// 		},
	// 		{
	// 			title: 'Is Real',
	// 			name: 'is_real_input',
	// 			type: 'select',
	// 			yes_or_no: true,
	// 		},
	// 	], [canFilterByDepartment])

	const updateTable = () => {
		if (updateProfile) {
			updateProfile()
		}
		baseTableMethodsRef.current.updateTable()
	}

  const clearingSelectedRowsList = () => {
    setSelectedRowIds([])
  }

	useImperativeHandle(methodsRef, () => ({
		updateTable: () => {
			updateTable()
		}
	}))

	const deleteTransactionsHandler = (ids) => {
		if (!ids) ids = selectedRows.map(i => i.id)

		if (confirm('Are you sure to delete transactions with ids: ' + ids.join(', '))) {
			deleteTransactions(ids).then(r => {
				toast.success(r.message)
        updateTable()
        setSelectedRows([])
        setSelectedRowIds([])
      })
		}
	}

	const selectedRowsHandler = (row) => {
		setSelectedRows(row)
		setSelectedRowIds(row.map((row) => row.id))
	}

	const changeStatusHandler = () => {
		openModal({
			title: 'Change Status',
			content: <ChangeStatus selectedRows={selectedRows} updateTable={updateTable}/>
		})
	}

  const editTransferHandler = (id) => {
    openModal({
      title: 'Edit transaction',
      content: <EditTransfer transferId={id} selectedRows={selectedRows} updateTable={updateTable}/>,
      size: 'xl',
      fullscreen: 'lg',
    })
  }

	useEffect(() => {
    if (walletId) {
      updateTable()
    }
	}, [walletId])

  const contextActions = (
		<BaseActionDropdown key="actionsDropdown">
			{canEditTransaction &&
				<>
					<DropdownItem onClick={changeStatusHandler}>Change status</DropdownItem>
        </>
			}
			{canDeleteTransaction &&
				<DropdownItem onClick={() => {
					deleteTransactionsHandler(selectedRows.map(i => i.id))
				}}>
					Delete
				</DropdownItem>}
		</BaseActionDropdown>
	)

	return (
		<BaseTable
			columns={columns}
			title="Transactions"
			filterFields={withFilters ? filters : []}
			url={dataUrl}
			additionalRequest={walletId ? {wallet_id: walletId} : null}
			selectedRowsHandler={selectedRowsHandler}
			methodsRef={baseTableMethodsRef}
			actions={contextActions}
			columnsSetting={columnsSetting}
			columnsSettingHandler={setColumnsSetting}
      conditionalRowStyles={[{when: row => !row.row_color, classNames: ['rdt_Table-row-odd']},
				{when: (row) => selectedRowIds.includes(row.id),
					classNames: ['selected_Row']
				}]}
      storageName={storageName}
      tableRowsPerPage={tableRowsPerPage}
      clearingSelectedRowsList={clearingSelectedRowsList}
      filterStorageKey={filterStorageKey}
      // conditionalRowStyles={[{when: row => !row.row_color, style: {backgroundColor: '#fff'}}]}
		/>
	)
}

export default TransactionsTable
