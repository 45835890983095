import React, {useContext, useEffect, useState} from 'react'
import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import {ModalContext} from "../../../../contexts"
import {getMeta, updateSecurity} from "../../../../api/securities"
import {toast} from "react-toastify"
import {selectListData} from "../../../../api/general"
import Select from "react-select"

const EditSecurity = (props) => {
  const {data, updateTable} = props
  const {closeModal} = useContext(ModalContext)
  const [fieldsList, setFieldsList] = useState([])
  const [securityOptions, setSecurityOptions] = useState([])
  const [selectedSecuritiesFields, setSelectedSecuritiesFields] = useState({})
  const [securityName, setSecurityName] = useState('')
  const [metaData, setMetaData] = useState({})
  const [pagesOption, setPagesOption] = useState([])
  const [securityPage, setSecurityPage] = useState()

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = {
      name: securityName,
      type: data.type,
      page_id: securityPage?.value || '',
      meta: metaData
    }

    updateSecurity(data.id, formData).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  const getSecuritiesMeta = (type) => {
    getMeta(type).then(r => {
      if (r.success) {
        setSelectedSecuritiesFields(r.fields)
      } else {
        toast.error(r.message)
      }
    })
  }

  const initializeData  = (data) => {
    setMetaData(data.meta)
  }

  const findKey = (label) => {
    for (const key in selectedSecuritiesFields) {
      if (selectedSecuritiesFields[key].label === label) {
        return key
      }
    }

    return null
  }

  const changeMetaValuesHandler = (value, key) => {
    setMetaData(prevMetaData => ({
      ...prevMetaData,
      [key]: value || ''
    }))
  }

  useEffect(() => {
    if (data && data?.type) {
      getSecuritiesMeta(data.type)
    }
  }, [data])

  useEffect(() => {
    selectListData('securities_types_list').then(r => {
      setSecurityOptions(r)
    })
  }, [])

  useEffect(() => {
    setSecurityName(data.name)
  }, [data])

  useEffect(() => {
    if (securityOptions?.length) {
      getSecuritiesMeta(securityOptions.find(i => i.value === data.type)?.value)
    }
  }, [securityOptions])

  useEffect(() => {
    initializeData(data)
  }, [data])

  useEffect(() => {
    selectListData('pages_list').then(r => {
      setPagesOption(r)
    })
  }, [])

  useEffect(() => {
    if (data.page_id) {
      setSecurityPage(pagesOption.find(i => i.value === data.page_id))
    }
  }, [pagesOption, data])

  return (
    <>
      <Form onSubmit={e => submitHandler(e)}>
        <Row>
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Name</Label>
            <Input
              name="name"
              required
              placeholder="Name"
              autoComplete="off"
              value={securityName}
              onChange={e => setSecurityName(e.target.value)}
            />
          </div>

          <div className="form-group mb-3">
            <Label htmlFor="choices-multiple-default" className="form-label mb-0">Page</Label>
            <Select
              placeholder="Choose..."
              isClearable={true}
              name="page_id"
              value={securityPage}
              onChange={selected => setSecurityPage(selected)}
              options={pagesOption}
            />
          </div>

          {Object.values(selectedSecuritiesFields).length ?
            Object.values(selectedSecuritiesFields).map((field, idx) => {
              const isCheckBox = field.type === 'checkbox'
              const isPopular = field.label === 'Popular'
              const popularMessage = 'Check the box to display it in the popular list'

              const key = findKey(field.label)

              return (
                <Col
                  className={`form-group mb-2 col-12 col-lg-6
                    ${isCheckBox ? 'd-flex align-items-center flex-row-reverse justify-content-end' : ''}`
                  }
                  key={idx}
                >
                  <Label className={`custom-label ${isCheckBox ? 'pb-1' : ''}`}>
                    {field.label}
                    {isPopular && <span className="ms-1 fs-12 text-muted">({popularMessage})</span>}
                  </Label>
                  <Input
                    placeholder="Enter the value"
                    name={key || `field_${idx}`}
                    type={field.type}
                    value={isCheckBox ? undefined : (metaData[key] || '')}
                    checked={isCheckBox ? (metaData[key] || false) : undefined}
                    onChange={e =>
                      changeMetaValuesHandler(isCheckBox ? e.target.checked : e.target.value, key)
                    }
                    autoComplete='off'
                  />
                </Col>
              )
            }) : null
          }
        </Row>

        <div className="d-flex justify-content-between mt-3">
          <Button type="button" color="warning" onClick={closeModal} className="btn">Cancel</Button>
          <Button type="submit" color="primary" className="btn">Submit</Button>
        </div>
      </Form>
    </>
  );
}

export default EditSecurity;
