import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
import {ModalContext} from "../../contexts"
import BreadCrumb from '../../Components/Common/BreadCrumb'
import BaseTable from '../../Components/Tables/BaseTable'
import BaseActionDropdown from "../../Components/Tables/Actions/BaseActionDropdown"
import {toast} from "react-toastify"
import {setPinCodes, revokePinCodes, sendToEmails, deleteEmployee} from "../../api/employees"
import CreateEmployee from "./Modals/CreateEmployee"
import CreateAdmin from "./Modals/CreateAdmin"
import CreateAffiliate from "./Modals/CreateAffiliate"
import {setPinCode, revokePinCode} from "../../api/employees"
import {Link} from "react-router-dom"
import {copyToClipboard} from "../../helpers/utils"
import {useUserCan, useUserHasRole} from "../../Components/Hooks/UserHooks"
import {prettyDate} from "../../helpers/utils"
import {filtersList} from "../../api/general"

const Employees = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const baseTableMethodsRef = useRef(null)
  const {openModal} = useContext(ModalContext)
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [filters, setFilters] = useState([])
  const tableRowsPerPage = 'employees_rows_per_page'
  let filterStorageKey = 'employees_filter'

  // Permissions
  const superAdminRolePermissions = useUserHasRole('super_admin')
  const canCreateUser = useUserCan('create_user')
  const canViewUser = useUserCan('view_user')
  const canSetPinCode = useUserCan('set_pin_code')
  const canFilterByDepartment = useUserCan('filter_by_department')
  const canDeleteUser = useUserCan('delete_user')

  const selectedRowsHandler = (rows) => {
    setSelectedRows(rows)
    setSelectedRowIds(rows.map((row) => row.id))
  }

  const columns = useMemo(() => [
    {
      id: "id",
      name: <span className='font-weight-bold fs-14'>ID</span>,
      selector: canViewUser ? (row => <Link to={`/employees/show/${row.id}`}>#{row.id}</Link>) : (row => <>#{row.id}</>),
      sortable: true,
      minWidth: '88px',
      maxWidth: '300px',
      omit: false
    },
    {
      id: "name",
      name: <span className='font-weight-bold fs-14'>Name</span>,
      selector: row => row.name,
      minWidth: '180px',
      maxWidth: '300px',
      omit: false,
    },
    {
      id: "email",
      name: <span className='font-weight-bold fs-14'>Email</span>,
      selector: row => <span className="cursor-pointer" onClick={() => copyToClipboard(row.email)}>
          {row.email}
        </span>,
      minWidth: '200px',
      maxWidth: '300px',
      omit: false,
    },
    {
      id: "role",
      name: <span className='font-weight-bold fs-14'>Role</span>,
      selector: row => row.roles[0].name,
      minWidth: '80px',
      maxWidth: '300px',
      omit: false,
    },
    {
      id: "pin_code",
      name: <span className='font-weight-bold fs-14'>Pin Code</span>,
      selector: row => (
        row.profile.settings?.is_pin_code_disabled ?
        "Disabled" : (row.profile.pin_code ? row.profile.pin_code : "-")
      ),
      minWidth: '55px',
      maxWidth: '300px',
      omit: !canSetPinCode,
    },
    {
      id: "teams",
      name: <span className='font-weight-bold fs-14'>Teams</span>,
      selector: row => row?.teams[0]?.name,
      minWidth: '80px',
      maxWidth: '300px',
      omit: false,
    },
    {
      id: "departments",
      name: <span className='font-weight-bold fs-14'>Departments</span>,
      selector: row => row?.departments[0]?.name,
      minWidth: '105px',
      maxWidth: '300px',
      omit: false,
    },
    {
      id: "registered_at",
      name: <span className='font-weight-bold fs-14'>Registered At</span>,
      selector: row => (prettyDate(row.created_at)),
      minWidth: '155px',
      maxWidth: '300px',
      omit: false,
    },
    {
      id: "actions",
      name: <span className='font-weight-bold fs-14'>Actions</span>,
      selector: row => (
        <>
          <div className="hstack gap-3 flex-wrap">
            {canSetPinCode && row.roles[0].name === 'agent' ? row.profile.pin_code ?
                <Link to="#" onClick={() => {
                  revokePinCodeHandler(row.id)
                }} className="link-danger fs-15">
                  <i className="ri-barcode-line"></i>
                </Link> :
                <Link to="#" onClick={() => {
                  setPinCodeHandler(row.id)
                }} className="link-primary fs-15">
                  <i className="ri-barcode-line"></i>
                </Link> :
              <></>
            }
            {canDeleteUser &&
              <Link to="#" onClick={() => {
                deleteEmployeeHandler(row.id)
              }} className="link-danger fs-15 ms-auto">
                <i className="ri-delete-bin-line"></i>
              </Link>}
          </div>
        </>
      ),
      minWidth: '75px',
      maxWidth: '300px',
      omit: false,
    },
  ], [canViewUser, canDeleteUser, canSetPinCode])

  useEffect(() => {
    filtersList(filterStorageKey).then(r => {
      setFilters(r)
    })
  }, [])

  // const filters = useMemo(
  //   () => [
  //     {
  //       title: 'Employee ID',
  //       name: 'employee_id',
  //       type: 'text',
  //     },
  //     {
  //       title: 'Employee Name',
  //       name: 'employee_name',
  //       type: 'text',
  //     },
  //     {
  //       title: 'Roles',
  //       name: 'roles',
  //       type: 'select',
  //       is_multiple: true,
  //     },
  //     (canFilterByDepartment &&
  //       {
  //         title: 'Departments',
  //         name: 'departments',
  //         type: 'select',
  //         is_multiple: true,
  //       }),
  //     {
  //       title: 'Team',
  //       name: 'teams',
  //       select_data_name: 'departments_teams',
  //       type: 'select',
  //       is_multiple: true,
  //     },
  //   ], [])

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  const clearingSelectedRowsList = () => {
    setSelectedRowIds([])
  }

  const setPinCodesHandler = () => {
    if (confirm('Do you want to set pin codes for selected agents?')) {
      setPinCodes(selectedRows.map(i => i.id)).then(res => {
        toast.success(res.message)
        updateTable()
      })
    }
  }

  const setPinCodesForAllHandler = () => {
    if (confirm('Do you want to set pin codes for All agents?')) {
      setPinCodes(selectedRows.map(i => i.id)).then(res => {
        toast.success(res.message)
        updateTable()
      })
    }
  }

  const revokePinCodesHandler = () => {
    if (confirm('Do you want to revoke pin codes for selected agents?')) {
      revokePinCodes(selectedRows.map(i => i.id)).then(res => {
        toast.success(res.message)
        updateTable()
      })
    }
  }

  const sendPinCodesToEmailHandler = () => {
    if (confirm('Do you want to set pin codes to emails for selected agents?')) {
      sendToEmails(selectedRows.map(i => i.id)).then(r => {
        toast.success(r.message)
        updateTable()
      })
    }
  }

  const deleteEmployeeHandler = (id) => {
    if (confirm('Do you really want to delete employee?')) {
      deleteEmployee(id).then(r => {
        toast.success(r.message)
        updateTable()
      })
    }
  }

  const setPinCodeHandler = (id) => {
    if (confirm('Do you want to set pin code?')) {
      setPinCode(id).then(r => {
        toast.success(r.message)
        updateTable()
      })
    }
  }

  const revokePinCodeHandler = (id) => {
    if (confirm('Do you want to revoke pin code?')) {
      revokePinCode(id).then(r => {
        toast.success(r.message)
        updateTable()
      })
    }
  }

  const createEmployeeHandler = () => {
    openModal({
      title: "Create Employee",
      content: <CreateEmployee updateTable={updateTable}/>
    })
  }

  const createAdminHandler = () => {
    openModal({
      title: "Create Admin",
      content: <CreateAdmin updateTable={updateTable}/>
    })
  }

  const createAffiliateHandler = () => {
    openModal({
      title: "Create Affiliate",
      content: <CreateAffiliate updateTable={updateTable}/>
    })
  }

  const contextActions = (
    <BaseActionDropdown key="actionsDropdown">
      <DropdownItem onClick={setPinCodesHandler}>Set PIN codes</DropdownItem>
      <DropdownItem onClick={revokePinCodesHandler}>Revoke PIN codes</DropdownItem>
      <DropdownItem onClick={sendPinCodesToEmailHandler}>Send PIN codes to email</DropdownItem>
    </BaseActionDropdown>
  )

  document.title = 'Employees | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Employees"/>
          <Row>
            <Col xs={12}>
              <div className="flex-shrink-0">
                <div className="d-flex flex-sm-row flex-column">
                  {canCreateUser &&
                    <UncontrolledDropdown>
                      <DropdownToggle tag="button" className="btn btn-success mb-3 me-2">
                        Create <i className="mdi mdi-chevron-down"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-lg-end">
                        <DropdownItem onClick={createEmployeeHandler}>Create Employee</DropdownItem>
                        {superAdminRolePermissions &&
                          <DropdownItem onClick={createAdminHandler}>Create Admin</DropdownItem>}
                        {superAdminRolePermissions &&
                          <DropdownItem onClick={createAffiliateHandler}>Create Affiliate</DropdownItem>}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                  {canSetPinCode &&
                    <div>
                      <Button
                        type="button"
                        className="btn mb-3"
                        color="primary"
                        onClick={setPinCodesForAllHandler}
                      >
                        Set PIN codes for "All" agents
                      </Button>
                    </div>
                  }
                </div>
              </div>
            </Col>
          </Row>
          <BaseTable
            columns={columns}
            title="Employees"
            filterFields={filters}
            url="/spa/employees/data"
            actions={canSetPinCode && contextActions}
            selectedRowsHandler={selectedRowsHandler}
            methodsRef={baseTableMethodsRef}
            conditionalRowStyles={[{
              when: (row) => selectedRowIds.includes(row.id),
              classNames: ['selected_Row']
            }
            ]}
            tableRowsPerPage={tableRowsPerPage}
            filterStorageKey={filterStorageKey}
            clearingSelectedRowsList={clearingSelectedRowsList}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Employees
