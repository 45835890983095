import {Button, Form, FormGroup, Input, Label} from "reactstrap"
import {useContext, useState} from "react"
import {ModalContext} from "../../../contexts"
import {editDocument} from "../../../api/settings";
import {toast} from "react-toastify";

const EditDocument = ({ type, id, onSave, isHidden }) => {
  const {closeModal} = useContext(ModalContext)
  const [documentsType, setDocumentsType] = useState(type)
  const [checked, setChecked] = useState(isHidden)

  const documentChangeHandler = (e) => {
    setDocumentsType(e.target.value)
  }

  const saveHandler = (e) => {
    e.preventDefault()

    const data = {
      document_type_id: id,
      hidden_from_customer: checked,
      type: documentsType,
    }

    editDocument(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        onSave()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  return (
    <>
      <Form onSubmit={saveHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Type name</Label>
          <Input
            placeholder='Address'
            autoComplete="off"
            value={documentsType}
            onChange={documentChangeHandler}
          />
        </div>
        <div className="form-check form-switch form-switch-left form-switch-sm mb-3">
          <Input
            className="form-check-input"
            type="checkbox"
            checked={checked}
            onChange={e => {setChecked(e.target.checked)}}
          />
          <Label className="form-check-label text-muted" for="hidden_from_customer">
            Hidden from customer
          </Label>
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default EditDocument
