import React, {useEffect, useState} from 'react'
import {Button, Card, CardBody, Col, Container, Row, Spinner, Table} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {Link, useParams, useNavigate} from "react-router-dom"
import {getInvoice, updateInvoice} from "../../api/invoices"
import {toDateFormat} from "../../helpers/utils"
import {getProfile} from "../../api/customers"
import moment from "moment"
import {getBrands} from "../../api/settings"
import ItemsDetailsRowsTable from "./Components/ItemsDetailsRowsTable"
import DatesDetailsFields from "./Components/DatesDetailsFields"
import EditDatesFields from "./Components/EditDatesFields"
import ItemsEditRowsTable from "./Components/ItemsEditRowsTable"
import {toast} from "react-toastify"

const InvoiceDetails = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const [invoiceData, setInvoiceData] = useState({})
  const [profileData, setProfileData] = useState({})
  const [brand, setBrand] = useState([])
  const [isInvoiceEdit, setIsInvoiceEdit] = useState(false)
  const [dueDate, setDueDate] = useState('')
  const [invoiceDate, setInvoiceDate] = useState('')
  const [termsDays, setTermsDays] = useState('')
  const [date, setDate] = useState(moment())
  const [rows, setRows] = useState([])
  const [subtotal, setSubtotal] = useState(Number())
  const [totalAmountValue, setTotalAmountValue] = useState(Number())
  const [discount, setDiscount] = useState('')
  const [tax, setTax] = useState('')

  useEffect(() => {
    if (invoiceData) {
      const dueDate = toDateFormat(invoiceData.due_date)
      const invoiceDate = toDateFormat(invoiceData.invoicing_date)

      const dueDateFormatted = moment(dueDate, 'YYYY-MM-DD')
      const invoiceDateFormatted = moment(invoiceDate, 'YYYY-MM-DD')

      if (dueDateFormatted && invoiceDateFormatted) {
        setTermsDays(dueDateFormatted.diff(invoiceDateFormatted, 'days'))
        setDueDate(dueDate)
        setInvoiceDate(invoiceDate)
      }
    }
  }, [invoiceData])

  const getInvoiceData = () => {
    getInvoice(id).then((r) => {
      setInvoiceData(r)
      getProfileData(r.customer_id)
    })
  }

  const getBrandData = () => {
    getBrands().then(r => {
      setBrand(r.find(i => String(invoiceData.mail_from) === String(i.id)))
    })
  }

  const getProfileData = (id) => {
    getProfile(id || invoiceData.customer_id).then((r) => {
      setProfileData(r)
    })
  }

  const saveInvoiceHandler = () => {

    const data = {
      invoicing_date: invoiceDate,
      due_date: dueDate,
      invoice_id: invoiceData.id,
      total_amount: totalAmountValue,
      subtotal_amount: subtotal,
      rows: JSON.stringify(rows),
      discount: discount,
      tax: tax,
      payments_terms: termsDays
    }

    updateInvoice(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        setIsInvoiceEdit(false)
        getInvoiceData()
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })

  }

  const handleTermsChange = (e) => {
    const newTermsDays = e.target.value
    setTermsDays(newTermsDays)
    calculateDueDate(newTermsDays)
  }

  const handleDateChange = (e) => {
    const newDate = e.target.value
    setInvoiceDate(newDate)
    calculateDueDate(termsDays, newDate)
  }

  const handleDueDateChange = (e) => {
    const newDueDate = e.target.value
    setDueDate(newDueDate)

    const dueDateFormatted = moment(newDueDate, 'YYYY-MM-DD')
    const invoiceDateFormatted = moment(invoiceDate, 'YYYY-MM-DD')

    if (dueDateFormatted.isValid() && invoiceDateFormatted.isValid()) {
      const newTermsDays = dueDateFormatted.diff(invoiceDateFormatted, 'days')
      setTermsDays(newTermsDays)
    }
  }

  const calculateDueDate = (daysCount, date = invoiceDate) => {
    const dueDateOffset = daysCount || parseInt(termsDays, 10)
    const calculatedDueDate = moment(date).add(dueDateOffset, 'days')

    setDueDate(formatDate(calculatedDueDate))
  }

  const addRow = () => {
    const newRow = {
      id: Math.max(...rows.map(row => row.id)) + 1,
      product_description: '',
      quantity: '',
      rate: '',
      amount: 0.00,
    }

    setRows([...rows, newRow])
  }

  const deleteRow = (id) => {
    if (rows.length === 1) {
      const newId = rows[0].id + 1
      setRows([{
        id: newId,
        product_description: '',
        quantity: '',
        rate: '',
        amount: 0.00,
      }])
    } else {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const handleQuantityChange = (event, id) => {
    const newValue = event.target.value
    if (!isNaN(newValue) || newValue === "") {
      updateRow(id, {quantity: newValue})
    }
  }

  const handleRateChange = (event, id) => {
    const newValue = event.target.value
    if (!isNaN(newValue) || newValue === "") {
      updateRow(id, {rate: newValue})
    }
  }

  const handleProductDescriptionChange = (event, id) => {
    const newValue = event.target.value
    updateRow(id, {product_description: newValue})
  }

  const updateRow = (id, updateValue) => {
    const updateRows = rows.map((row) => {
      if (row.id === id) {
        const updatedRow = {...row, ...updateValue}
        updatedRow.amount = updatedRow.quantity * updatedRow.rate

        return updatedRow
      }

      return row
    })
    setRows(updateRows)
  }

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD")
  }

  const printInvoice = () => {
    window.print()
  }

  const calculateDiscountAmount = () => {
    const discountValue = parseFloat(discount)
    if (isNaN(discountValue) || !discount) {
      return 0
    }

    return (subtotal * (discountValue / 100))
  }

  useEffect(() => {
    getInvoiceData()
  }, [])

  useEffect(() => {
    getBrandData()
  }, [invoiceData])

  useEffect(() => {
    if (invoiceData && invoiceData?.items?.length) {
      setRows(invoiceData?.items)
      setTax(invoiceData.tax)
      setDiscount(invoiceData.discount)
    }
  }, [invoiceData])

  useEffect(() => {
    const newSubtotal = rows.reduce((acc, row) => acc + row.amount, 0)
    setSubtotal(newSubtotal)
  }, [rows])

  useEffect(() => {
    const discountAmount = calculateDiscountAmount()
    const taxAmount = (subtotal - discountAmount) * (tax / 100)
    setTotalAmountValue(subtotal - discountAmount + taxAmount)
  }, [subtotal, tax, discount])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Invoice"/>
          <Row className="d-flex justify-content-center">
            <Col className={
              !isInvoiceEdit ?
                'col-md-12 col-lg-10 col-xl-8 col-xxl-6' :
                'col-12 col-xxl-10'
            }>
              <Card>
                <CardBody>
                  {/* Header */}
                  <Row className="row d-flex justify-content-between mt-3 mb-2">
                    <Col xs={12} sm={6} md={6} lg={6} className="flex-column align-items-center mb-3">
                      {brand ?
                        <div className="d-flex flex-column">
                          <img
                            src={`/storage/${brand?.logo_path}`}
                            alt={brand?.title}
                            width="190"
                            className="img-fluid pb-1"
                          />
                          <h3>{brand?.title}</h3>
                          <p className="fs-16 text-muted mb-0">
                            {brand?.address}
                          </p>
                        </div> :
                        invoiceData.mail_from === "custom" ? (
                            <div className="mb-3">
                              <img
                                src={invoiceData.meta.custom_from ? `/storage/${invoiceData.meta.custom_from.logo}` : ''}
                                alt="brand_logo"
                                width="200"
                                className="img-fluid pt-2 pb-1"
                              />
                              <h5>{invoiceData.meta.custom_from.custom_name}</h5>
                              <p className="fs-16 text-muted mb-1">
                                {invoiceData.meta.custom_from.custom_address &&
                                  invoiceData.meta.custom_from.custom_address.split(/\r?\n/)
                                    .map((string, idx) => (
                                      <React.Fragment key={idx}>
                                        <span>{string}</span> <br/>
                                      </React.Fragment>
                                    ))
                                }
                              </p>
                              <p className="fs-16 text-muted">
                                {invoiceData.meta.custom_from.custom_info}
                              </p>
                            </div>
                          ) :
                          (
                            <div className="d-flex justify-content-center">
                              <Spinner className="flex-shrink-0"></Spinner>
                            </div>
                          )
                      }

                      <div>
                        <h5 className="mb-1">Bill to:</h5>
                        <p className="fs-16">{profileData?.data?.name || 'Loading...'}</p>
                      </div>
                    </Col>
                    <Col
                      xs={12} sm={6} md={6} lg={6}
                      className="d-flex flex-column"
                    >
                      <Table responsive className="mt-3 fs-14">
                        <tbody>
                        <tr className="border-top">
                          <td><strong>Invoice Id:</strong></td>
                          <td className="text-end">{invoiceData.uniq_id}</td>
                        </tr>
                        {!isInvoiceEdit &&
                          <DatesDetailsFields
                            invoiceDate={invoiceDate}
                            dueDate={dueDate}
                            termsDays={termsDays}
                          />
                        }
                        </tbody>
                      </Table>
                      {isInvoiceEdit &&
                        <EditDatesFields
                          handleDateChange={handleDateChange}
                          date={invoiceDate}
                          handleTermsChange={handleTermsChange}
                          paymentsTerms={termsDays}
                          dueDate={dueDate}
                          handleDueDateChange={handleDueDateChange}
                        />
                      }
                    </Col>
                  </Row>

                  {/* Items and summary */}
                  <Row className="d-flex justify-content-end">
                    <Col xs={12} className="mb-3">
                      {!isInvoiceEdit ?
                        <ItemsDetailsRowsTable invoiceData={invoiceData} />
                        :
                        <ItemsEditRowsTable
                          rows={rows}
                          handleProductDescriptionChange={handleProductDescriptionChange}
                          handleQuantityChange={handleQuantityChange}
                          handleRateChange={handleRateChange}
                          code={invoiceData.formatted_code}
                          deleteRow={deleteRow}
                          addRow={addRow}
                        />
                      }


                    </Col>
                    <Col xs={12} sm={6} md={5} lg={4} className="mb-3">
                      <Table>
                        <tbody>
                        <tr className="fw-medium">
                          <td>Sub Total:</td>
                          <td className="text-end">
                            {Number(isInvoiceEdit ? subtotal : invoiceData.subtotal_amount).toFixed(2)} {invoiceData.formatted_code}
                          </td>
                        </tr>
                        {isInvoiceEdit ?
                          <>
                            <tr>
                              <td><span className="input-group-text">Discount</span></td>
                              <td>
                                <div className="input-group flex-nowrap">
                                  <input
                                    name="discount"
                                    id="discount_input"
                                    type="text"
                                    className="calc-input form-control text-end"
                                    defaultValue={discount}
                                    onChange={(e) => {
                                      const value = e.target.value
                                      const formattedValue = value.replace(",", ".");
                                      if (formattedValue === "" || !isNaN(parseFloat(value))) {
                                        setDiscount(formattedValue)
                                      }
                                    }}
                                    placeholder="0 %"
                                    autoComplete="off"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="input-group-text">Tax</span>
                              </td>
                              <td>
                                <div className="input-group flex-nowrap">
                                  <input
                                    name="tax"
                                    id="tax_input"
                                    type="text"
                                    className="calc-input form-control text-end"
                                    defaultValue={tax}
                                    onChange={(e) => {
                                      const value = (e.target.value)
                                      const formattedValue = value.replace(",", ".");
                                      if (formattedValue === "" || !isNaN(parseFloat(value))) {
                                        setTax(formattedValue)
                                      }
                                    }}
                                    placeholder="0 %"
                                    autoComplete="off"
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                          :
                          <>
                            <tr>
                              <td>Discount:</td>
                              <td className="text-end">{invoiceData.discount ? invoiceData.discount : '0'}%</td>
                            </tr>
                            <tr>
                              <td>Tax:</td>
                              <td className="text-end">{invoiceData.tax ? invoiceData.tax : '0'}%</td>
                            </tr>
                          </>
                        }
                        <tr className="table-active">
                          <td className="fw-bold">Total:</td>
                          <td className="text-end fw-bold">
                            {Number(isInvoiceEdit ? totalAmountValue : invoiceData.total_amount).toFixed(2)} {invoiceData.formatted_code}
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  {/* Buttons */}
                  <Row className="mb-3 d-print-none">
                    <div className="d-flex justify-content-between">
                      <Button
                        color="primary"
                        className="btn"
                        onClick={() => !isInvoiceEdit ? navigate(-1) : setIsInvoiceEdit(false)}
                      >
                        {!isInvoiceEdit ? 'Back' : 'Cancel'}
                      </Button>
                      <div className="d-flex">
                        <Link
                          to="#"
                          onClick={() => !isInvoiceEdit ? setIsInvoiceEdit(true) : saveInvoiceHandler()}
                          className={`btn btn-${isInvoiceEdit ? 'success' : 'secondary'}`}
                        >
                          <i
                            className={`mdi mdi-${!isInvoiceEdit ? 'clipboard-edit' : 'content-save-move'}-outline align-bottom me-1`}></i>
                          {isInvoiceEdit ? 'Save' : 'Edit'}
                        </Link>
                        {!isInvoiceEdit &&
                          <Link to="#" onClick={printInvoice} className="btn btn-success ms-3">
                            <i className="ri-printer-line align-bottom me-1"></i>
                            Print
                          </Link>}
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InvoiceDetails
