import {APIClient} from "../helpers/api_helper"

const api = new APIClient();

// Create a new customer
export function createCustomer(data) {
    return api.post('/spa/create-user', data)
}

// Get data
export function getProfile(id) {
    return api.get(`/spa/customer/profile/${id}`, {})
}

export function getComments(id) {
    return api.get(`/spa/customer/profile/comments/${id}`, {})
}

export function getActivityLog(id, page) {
    return api.get(`/spa/customer/profile/activity-log/${id}`, {page: page || 1})
}

export function getDocuments(id) {
    return api.get(`/spa/customer/profile/documents/${id}`, {})
}

// Adding entities
export function addDocument(data) {
    return api.create('/spa/customer/profile/add-document', data)
}

export function addComment(customerId, message) {
    return api.create('/spa/customer/profile/add-comment', {user_id: customerId, message: message})
}

export function editComment(id, data) {
  return api.post(`/spa/customer/profile/edit-comment/${id}`, data)
}

export function deleteComment(id){
  return api.post(`/spa/customer/profile/delete-comment/${id}`, {})
}

// Editing
export function editProfile(id, data) {
    return api.post(`/spa/customer/profile/${id}/edit`, data)
}

export function changeStatus(status, customer_ids) {
    return api.post('/spa/customer/change-status', {status, customer_ids})
}

export function changeAdditionalStatus(status, customer_ids) {
  return api.post('/spa/customer/change-additional-status', {status, customer_ids})
}

export function changePlan(plan, customer_ids) {
    return api.post('/spa/customer/change-plan', {plan, customer_ids})
}

export function changeVerificationStatus(status, customer_ids) {
    return api.post('/spa/customer/change-verification-status', {verification_plan: status, customer_ids})
}

export function changeDocumentStatus(document_id, status) {
    return api.post('/spa/customer/profile/change-document-status', {document_id, status})
}

export function changeDepartment(department_id, customer_ids) {
    return api.post('/spa/customer/change-department', {department_id, customer_ids})
}

export function changePassword(data) {
  return api.post('/spa/customer/change-password', data)
}

// Actions
export function attachEmployee(agent_id, customer_ids) {
    return api.post('/spa/customer/attach-employee', {agent_id, customer_ids})
}

export function detachEmployee(data) {
    return api.post('/spa/customer/detach-employee', {customer_ids: data})
}

export function sendEmail(data) {
    return api.post('/spa/customer/send-mail', data)
}

export function sendToRecipient(data) {
  return api.post('/spa/customer/send-to-recipient', data)
}

export function generateTempPassword(data) {
  return api.post('/spa/customer/generate-temp-password', data)
}

export function loginAs(data) {
  return api.post('/spa/customer/login-as', data)
}

export function navigateNextCustomer(data) {
    return api.get('/spa/customer/navigate', data)
}

export function deleteCustomerDocument(id) {
  return api.post('/spa/customer/profile/delete-document', {user_document_id: id})
}

export function deleteCustomer(data) {
  return api.post('/spa/customer/delete-customer', {customer_ids: data})
}

export function clickToCall(id, data) {
  return api.post(`/spa/customer/call/${id}`, data)
}

export function disable2Fa(id) {
  return api.post(`/spa/customer/disable-2fa/${id}`, {})
}
