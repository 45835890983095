import React, {useContext, useEffect, useState} from 'react'
import {Button, Input} from "reactstrap"
import {ModalContext} from "../../../contexts"
import {toast} from "react-toastify"
import {useSelector} from "react-redux";

const SavePresetModal = (props) => {
  const {filterStorageKey, updatePresetsList} = props
  const {closeModal} = useContext(ModalContext)
  const [presetName, setPresetName] = useState('')
  const activeFilters = useSelector(s => s.TableFilters.filters)
  const [filtersData, setFiltersData] = useState([]);

  const savePresetHandler = () => {
    if (!Object.keys(filtersData).length) {
      toast.info('Filters are empty!')
      closeModal()

      return
    }

    let storagePresets = JSON.parse(localStorage.getItem(`${filterStorageKey}-presets`))
    let presetKeys = []
    let newStoragePresets = []

    if (storagePresets) {
      presetKeys = storagePresets.flatMap((preset) => Object.keys(preset))
    }

    if (storagePresets && storagePresets.length) {
      if (presetKeys.includes(presetName)) {
        toast.error('Such name already exists!')

        return
      }

      newStoragePresets = [...storagePresets, {[presetName]: filtersData}]
      localStorage.setItem(`${filterStorageKey}-presets`, JSON.stringify(newStoragePresets))
      toast.success('Preset saved successfully')
    } else {
      newStoragePresets = [...[], {[presetName]: filtersData}]
      localStorage.setItem(`${filterStorageKey}-presets`, JSON.stringify(newStoragePresets))
      toast.success('Preset saved successfully')
    }

    updatePresetsList()
    closeModal()
  }

  useEffect(() => {
    if (activeFilters.hasOwnProperty(filterStorageKey)) {
      setFiltersData(activeFilters[filterStorageKey])
    }
  }, [activeFilters]);

  return (
    <>
      <div>
        <Input
          type="text"
          className="form-control"
          placeholder="Preset name"
          value={presetName}
          onChange={e => setPresetName(e.target.value)}
        />

        <div className="d-flex justify-content-between mt-3">
          <Button type="button" className="btn" color="warning" onClick={closeModal}>Cancel</Button>
          <Button type="button" className="btn" color="success" onClick={() => {
            if (!presetName.length) {
              toast.error('Preset name is required')
              return
            }

            savePresetHandler()
          }}>Save</Button>
        </div>
      </div>
    </>
  )
}

export default SavePresetModal
